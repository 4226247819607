document.documentElement.classList.remove('no-js');

// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
if (import.meta.hot) {
  import.meta.hot.accept(() => {
    console.log("HMR");
  });
}

import header from './modules/header';
import tabbing from './modules/tabbing';
import skip from './modules/skip';
import nav from './modules/nav';
import dropdowns from './modules/dropdowns';
import filters from './modules/filters';
import backToTop from './modules/back-to-top';
import search from './modules/search';
import scrollLinks from './modules/scroll-links';
import carousel from './modules/carousel';
import popup from './modules/popup';
import progress from './modules/progress-bar';

header();
tabbing();
skip();
nav();
backToTop();
carousel();
scrollLinks();
filters.init();
dropdowns.init();
search.init();
popup();
progress();
