function progressBar() {
  document.addEventListener("DOMContentLoaded", function() {
    const progressbar = document.querySelector('.progress-bar');
    const progressbarinner = document.querySelector('.progress-bar__inner');
    const headerSection = document.querySelector('.js-header');

    // Check if the progress bar and header exists on the page
    if (!progressbar || !progressbarinner || !headerSection) {
      return; // Exit the function if there's no progress bar
    }

    // Get the height of the header section
    const headerHeight = headerSection.offsetHeight;

    // Dynamically set the --header-height CSS variable
    progressbar.style.setProperty('--header-height', `${headerHeight}px`);

    // Progress bar scrolling logic
    const heroSection = document.querySelector('.js-hero');
    const heroHeight = heroSection ? heroSection.offsetHeight : 0;

    // Setup a timer
    var timeout;
    
    // Listen for resize events
    window.addEventListener('scroll', function ( event ) {

      // If there's a timer, cancel it
      if (timeout) {
        window.cancelAnimationFrame(timeout);
      }

      // Setup the new requestAnimationFrame()
        timeout = window.requestAnimationFrame(function () {

        // Run our scroll functions
        let h = document.documentElement;
        let scrollTop = h.scrollTop || document.body.scrollTop;
        let scrollHeight = document.getElementById('content').scrollHeight;

        // Adjust scroll position to start after the hero section
        let scrolled = Math.max(0, scrollTop - heroHeight);

        // Calculate the percentage scrolled
        let percent = (scrolled / scrollHeight) * 100;

        // Ensure percentage is bounded between 0 and 100
        percent = Math.min(100, Math.max(0, percent));

        progressbarinner.style.width = percent + '%';

      });

    }, false);
  })
};

export default function() {
  progressBar();
}