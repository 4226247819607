import { gsap } from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
gsap.registerPlugin(ScrollToPlugin)

// Get the button:
let scrollToTopBtn = document.getElementById("back-to-top");

// When the user scrolls down 200px from the top of the document, show the button
window.onscroll = function() {scrollFunction()};
function scrollFunction() {
  if (
    (document.body.scrollTop > 300 || document.documentElement.scrollTop > 300)
  ) {
    scrollToTopBtn.classList.add("show");
  }  else {
    scrollToTopBtn.classList.remove("show");
  }
}

export default function() {
  document.addEventListener('DOMContentLoaded', function() {
    var scrollToTopBtn = document.getElementById('back-to-top');
    scrollToTopBtn.addEventListener('click', function() {
      gsap.to(window, { duration: 1, scrollTo: 0 });
    });
  });
}