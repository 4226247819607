import { gsap } from 'gsap';

export default function () {
  const popup = document.querySelector('#popup');

  if (!popup) return;

  // Ensure popup is hidden initially
  popup.style.display = 'none';

  // Function to show the popup
  function showPopup() {
    gsap.to(popup, {
      display: 'block', // Make the popup visible
      opacity: 1, // Fade in to fully visible
      duration: 0.2, // Slight fade-in effect
      ease: 'power1.out',
    });
  }

  // Set a timer for 8 seconds
  setTimeout(() => {
    showPopup();
  }, 8000);

  // Function to close the popup
  function closePopup() {
    gsap.to(popup, {
      opacity: 0,
      duration: 0.2,
      ease: 'power1.in',
      onComplete: () => {
        popup.style.display = 'none'; // Hide the popup after the fade-out animation
      },
    });

    // Remove popup position modifier class if user closes the popup
    const backToTopBtn = document.querySelector('.js-back-to-top');
    if (backToTopBtn && backToTopBtn.classList.contains('back-to-top--popup')) {
      backToTopBtn.classList.remove('back-to-top--popup');
    }
  }

  // Add event listener to the close button
  const popupCloseBtn = document.querySelector('.js-popup-close');
  if (popupCloseBtn) {
    popupCloseBtn.addEventListener('click', closePopup);
  }
}
